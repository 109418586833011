/* Food Item Button */
.button {
  background-color: #D0AE64;
  color: rgba(243, 237, 187, 0.815);
  width: 50%;
  border: #D0AE64 1px solid;
  border-radius: 7px;
  padding: 5px;
}

.button:hover{
  color: white;
  transition: 0.2s ease-in;
}

/* Active Menu */
.active_menu_tab {
  background-color: #272525;
}
  
.bg-custom-active {
  background-color: #272525;
}

.home-container {
  position: relative;
}

/* Banner Styling */

.banner-image {
  background-size: cover;
  background-position: center top;
  background-image: url('../../../assets/banner.jpg');
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  color: rgb(7, 29, 65);
}

.banner-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.500);
  z-index: 1;
}

.banner-text {
  color: white;
  animation: fadeInLeft 1.5s ease-in-out;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.banner-text h2 {
  font-size: 2.9em;
  margin: 0;
  margin-bottom: 10px;
}

.banner-text .typed-container {
  height: 1.5em; /* Set this to the height of one line of the typed text */
  display: flex;
  align-items: center; /* Center the text vertically */
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Menu Background */
.menu-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: linear-gradient(rgba(12, 12, 12, 0.990), rgba(12, 12, 12, 0.990)), 
  url('../../../assets/foodpattern.jpg'); */
  /* background: #0D0F14; */
  background: #f2f6facf;
  background-size: cover;
  z-index: -2;
  /* background: #1e1e1e; */
}

.menu_tab {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.active_menu_tab {
  background-color: #113153;
  color: white; /* Optional: Change text color for visibility */
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
